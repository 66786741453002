import * as React from 'react'

import AssetFileUploader from "./AssetFileUploader"
import Modal from 'react-modal';
import { AssetLibraryBrowser } from "../asset-components/AssetLibraryBrowser.js"

function AssetFileChooser({ assetLibrary, onAssetImport, ariaDescriptionId, onAssetLibrarySelected, onAssetLibraryDelete, onAssetLibraryChange, className, style, children, onWillBeginUploading, onError, onUploadFile, canCrop, canMultiUpload }) {
    
    const [showAssetLibrary, setShowAssetLibrary] = React.useState(false)

    const onAssetSelectedRef = React.useRef( (asset) => { 
        if (onAssetLibrarySelected) {
            onAssetLibrarySelected(asset)
        }
    })

    const hideLibrary =  () => { 
        setShowAssetLibrary(false)  
    }

    const showLibrary = () => {
        setShowAssetLibrary(true) 
    }

    if (assetLibrary) {
        var actionContent = <div style={style} className={className} onClick={showLibrary}>
                                {children}
                            </div>
        
        if (ariaDescriptionId) {
            actionContent = <button className="asset-file-chooser" onClick={showLibrary} aria-describedby={ariaDescriptionId}>
                                {actionContent}
                            </button>
        }

        return <div>
                    {actionContent}
                    <Modal contentLabel="Asset Library" ariaHideApp={false} className="modal modal-fullscreen" overlayClassName="modal-overlay" isOpen={showAssetLibrary} onRequestClose={hideLibrary}>
                            <AssetLibraryBrowser onCancel={hideLibrary}
                                                assets={assetLibrary}
                                                setShowAssetLibrary={setShowAssetLibrary}
                                                canCrop={canCrop}
                                                canMultiUpload={canMultiUpload}
                                                onAssetImport={onUploadFile}
                                                onAssetChange={onAssetLibraryChange}
                                                onAssetDelete={onAssetLibraryDelete}
                                                onAssetSelected={onAssetSelectedRef}/>
                    </Modal>
                </div>
        
    } else {
        return  <AssetFileUploader 
                    withDimensions={true}
                    style={style}
                    className={className}
                    canMultiUpload={canMultiUpload}
                    onWillBeginUploading={onWillBeginUploading}
                    onError={onError}
                    onUploadFile={onUploadFile}>
                        {children}
                    </AssetFileUploader>
                              
    }
}

export default AssetFileChooser